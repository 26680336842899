* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

::selection {
	/* background: #7d26cd30; */
	background: #ffa80030;
}

.str-video {
	background-color: #272a30;
	color: #ffffff;
	height: 100dvh;
	width: 100%;
	display: flex;
	flex-direction: column;
	min-width: 0;
	max-width: 100%;
  }


.stream-chat {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    height: 400px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.stream-chat.collapsed {
    height: 0;
    width: 0;
    opacity: 0;
    pointer-events: none;
}

.stream-chat.expanded {
    height: 400px;
    width: 300px;
    opacity: 1;
    pointer-events: all;
}


.chat-bubble {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: white;
    padding: 10px 15px;
    border-radius: 60px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.floating-chat {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 350px; /* Control the width of the chat window */
    height: 450px; /* Control the height of the chat window */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    overflow: hidden;
}

.floating-chat .str-chat {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.floating-chat .str-chat__list {
    flex-grow: 1;
    overflow-y: auto;
}

.floating-chat .str-chat__input-flat {
    flex-shrink: 0;
}


.str-chat__input-flat {
    padding: 10px;
}

.str-chat__fileupload-wrapper{

    display: none;
}

.str-chat__message-simple__actions{

    display: none;
}
